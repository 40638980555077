/* General Styling */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f9;
    margin: 0;
    padding: 0;
}

.applyCertSection {
    max-width: 90%;
    margin: 30px auto;
    margin-bottom: 30px!important;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.cert-titlemc {
    text-align: center!important;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Partner Container */
/* .partner-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
} */

.forPartner {
    flex: 1;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Styling */
h3, h4 {
    color: #333;
    font-weight: bold;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
}

/* Form Section Styling */
.form-section {
    margin-bottom: 20px;
}

.section-title {
    background-color: #80a1c1;
    color: white;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    text-align: center!important;
}

/* .section-title:hover {
    background-color: #1e3a8a;
} */

.section-content {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    margin: 30px 0;
}

input, select {
    width: 100%;
    padding: 10px;
    margin: -10px 0 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input:focus, select:focus {
    outline: none;
    border-color: #80a1c1;
    box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

/* Partner Details Styling */
.partner-card {
    background: #f8f9fa;
    padding: 15px;
    border-radius: 5px;
    margin: 30px 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.partner-card h3 {
    color: #507aec;
    margin-bottom: 10px;
}

.partner-info p {
    font-size: 16px;
    margin: 5px 0;
    color: #333;
}

.partner-info strong {
    color: #80a1c1;
}

/* Submit Button Styling */
.submit-buttonmc {
    display: block;
    margin: 20px auto;
    width: 350px;
    background-color: #80a1c1;
    color: white;
    padding: 12px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    text-align: center;
}

.submit-buttonmc:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-buttonmc:hover:not(:disabled) {
    background-color: #80a1c1;
}

/* Responsive Styling */
@media screen and (max-width: 800px) {
    .partner-container {
        flex-direction: column;
    }

    .submit-buttonmc {
        width: 100%;
    }
}

.input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.validated-input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 35px; /* Space for the checkmark */
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.input-icon {
    position: absolute;
    right: -15px;
    margin-top: -20px;
    color: green;
    font-size: 18px;
    background: transparent;
}
