/* YourComponent.css */

/* Reset some default styles for margin and padding */
body, h1, h2, h3, ul, li {
    margin: 0;
    padding: 0;
  }
  
  /* Style the container */
  .container {
    max-width: 100%;
    margin: 0 auto;
  }
  
  /* Style the navbar */
  .navbarNew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #80a1c1!important;
    color: white;
    padding: 1rem;
  }
  
  .logo img {
    max-width: 190px;
  }
  
  nav ul {
    list-style: none;
    display: flex;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav a {
    text-decoration: none;
    color: white;
  }
  
  /* Style the social bar */
  .social-bar {
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  
  .social-bar a {
    margin: 0 10px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  /* Style the header section */
  .headerOne {
    height: 50vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url('../../img/publicback.jpeg');
  background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .header-image img {
    max-width: 100%;
  }
  
  .search-bar {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  .search-bar input[type="text"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 300px;
  }
  
  .search-bar button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 70px 10px 20px;
    border-radius: 5px;
    margin-top: -10px;
    cursor: pointer;
  }
  
  /* Add more specific styles as needed */
  
  .soBarIcon{
    color: #fff;
    background: none!important;
  }

  .applycert{
    background: #f5f3ed;
    padding: 50px 0;
  }

  .w-richtext{
    background: #fff;
    border-radius: 3px;
    margin: 0px auto;
    padding: 30px;
    width: 65%;
    height: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1)!important;
  }
  .w-richtext p{
    margin-bottom: 30px;
  }
  .headBoxx{
    margin: 20px auto;
    height: 5px;
    width: 70%;
    background: #000;
  }
  .headingCert h4{
    font-weight: 700;
    font-size: 2rem!important;
    text-align: center;
  }

  .innerSec{
    padding: 50px 0 50px 80px;
  }

  .dropdown{
    margin-top: 50px;
  }

  .dropBtn{
    width: 80%;
    margin-top: 10px;
    background: #f5f3ed!important;
    padding: 20px!important;
    color: #000!important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    border: none!important;
    border-radius: 10px;
    margin-bottom: 50px;
  }
  .dropBtn:focus{
    border: none!important;
  }
  .dropdown-menu{
    width: 80%;
    
  }