.form-container {
    max-width: 90%;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-title {
    text-align: center;
    color: #1e3a8a;
    font-size: 24px;
    font-weight: bold;
    margin: 20px;
}

.form-section {
    background-color: #ffffff;
    padding: 15px;
    width: 80%;
    margin: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #1e3a8a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #e0f2fe;
}

.toggle-arrow {
    font-size: 16px;
    color: #1e3a8a;
}

.section-content {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.labelCert{
    margin-top: 20px;
    font-weight: bold;
    color: #1e3a8a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-left: 10px;
    border-radius: 5px;
    background: #e0f2fe;
}
input, select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

button {
    width: 20%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #3b82f6;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    margin-top: 10px;
    margin: 15px;
}

button:hover {
    background-color: #2563eb;
}
